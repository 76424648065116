generatechatbotframe();
function generatechatbotframe()
{
    var element=document.getElementById("re_botframe");
    
    var boturl=element.getAttribute("server-url");
    boturl=(boturl===null || boturl==='https://live.ipms247.com')?"https://bechatservice.ipms247.com":"http://"+boturl; //Prashant Patel - 05/10/2022 - Purpose : change domain - [RES-3140]
    //boturl+="/booking/chatbot/index.html";
    //style="box-shadow: 0 0px 10px 3px rgba(179, 179, 179, 0.5);"
    //var html='<iframe id="botframe" name="botframe" frameborder="0" width="1" height="1" scrolling="no" src="'+boturl+'?hotel_code='+element.getAttribute("data-hotelcode")+'&apikey='+element.getAttribute("data-authkey")+'"></iframe>';
    
    //console.log(element.getAttribute("data-hotelcode")+" <== data-hotelcode ");
    if(element.getAttribute("data-hotelcode")!==null)
        element.innerHTML='<iframe id="botframe" name="botframe" frameborder="0" style="width:1px;height:1px;" scrolling="no" src="'+boturl+'/bechatassistant/'+element.getAttribute("data-hotelcode")+'/'+element.getAttribute("data-authkey")+'"></iframe>';
    else if(element.getAttribute("data-groupcode")!==null)
        element.innerHTML='<iframe id="botframe" name="botframe" frameborder="0" style="width:1px;height:1px;" scrolling="no" src="'+boturl+'/groupbechatassistant/'+element.getAttribute("data-groupcode")+'/'+element.getAttribute("data-authkey")+'"></iframe>';
        
    document.getElementById("re_botframe").style['z-index']=9999999;
}

var initialize='';
var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent",
    eventer = window[eventMethod],
    messageEvent = "attachEvent" == eventMethod ? "onmessage" : "message";
    eventer(messageEvent, function(e) {
    var t = e.data;
    t=t.split("^");
    //console.log(t);
    
    if(t[0]!=undefined && t[1]!=undefined && t[2]!=undefined)
    {
        myMove(parseFloat(t[1]),parseFloat(t[2]),t[0],(t[2]=="100%")?'%':'px');
        if(initialize==='')
        {
            if(document.getElementById("re_botframe").getAttribute("data-groupcode")!==null)
            {
                document.getElementById('botframe').contentWindow.postMessage("TITLE:^"+document.getElementById("re_botframe").getAttribute('title'), '*');
            }
            initialize='init';
        }
    }
    
}, !1);

function myMove(height,width,chatwindow,maximize) {
    
    //console.log("height : "+height+" | width : "+width);
    
    if(width===undefined || height===undefined)
        return false;
    
    if(isNaN(width) || isNaN(height))
        return false;
    
    var elem = document.getElementById("botframe");
    var frame_ele=document.getElementById("re_botframe");
    
    if(chatwindow=='false')
    {
        elem.style.height = height+"px";
        elem.style.width = width+"px";
        
        frame_ele.style.height = height+"px";
        frame_ele.style.width = width+"px";
        
        elem.style.boxShadow="none";
        elem.style.borderRadius="50%";
    }
    else
    {
        var size=frame_ele.getAttribute("data-size");
        if(size!==null && maximize!='%')
        {
            size=size.split("x");
            if(size[0]!==undefined && size[0]!==null)
            {
                width=size[0];
            }
            if(size[1]!==undefined && size[1]!==null)
            {
                height=size[1];
            }
        }
    
        var current_height=elem.style.height;
        var current_width=elem.style.width;
        
        var height_partition=(height-current_height)/3;
        var width_partition=(width-current_width)/3;
        
        var pos = 0;
        var id = setInterval(frame, 80);
        function frame() {
            if (pos == 5) {
                clearInterval(id);
            } else {
                
                if(chatwindow=='true')
                {
                    elem.style.boxShadow="0 0px 10px 3px rgba(179, 179, 179, 0.5)";
                    elem.style.borderRadius="";
                }
                else
                {
                    elem.style.boxShadow="none";
                    elem.style.borderRadius="50%";
                }
                
                pos++;
                
                if(pos == 4 || height_partition>=height || width_partition>=width)
                {
                    elem.style.height = height+maximize; 
                    elem.style.width = width+maximize;
                    
                    frame_ele.style.height = height+maximize;
                    frame_ele.style.width = width+maximize;
                    clearInterval(id);
                    
                    if(chatwindow=='true')
                    {
                        elem.style.boxShadow="0 0px 10px 3px rgba(179, 179, 179, 0.5)";
                    }
                    else
                    {
                        elem.style.boxShadow="";
                    }
                }
                else
                {
                    elem.style.height = (parseFloat(current_height)+parseFloat(height_partition))+maximize; 
                    elem.style.width = (parseFloat(current_width)+parseFloat(width_partition))+maximize;
                    
                    frame_ele.style.height = (parseFloat(current_height)+parseFloat(height_partition))+maximize;
                    frame_ele.style.width = (parseFloat(current_width)+parseFloat(width_partition))+maximize;
                }
                
                height_partition+=parseFloat(height_partition);
                width_partition+=parseFloat(width_partition);
            }
        }
    }
}